@tailwind base;
@tailwind components;
@tailwind utilities;

button {
  color: inherit;
}

/* Will only apply on Firefox; WebKit for other browsers */
.scrollbar-faint {
  scrollbar-color: transparent;
  scrollbar-width: 6;
}

.scrollbar-faint::-webkit-scrollbar {
  background: transparent;
  width: 6;
}

.scrollbar-faint::-webkit-scrollbar-thumb {
  background: #48474b;
  border-radius: 10;
}
